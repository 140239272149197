<template>
	<div class="schedule">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>お支払い方法</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									お支払い方法
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-lg-6 mb-5">
						<h3>キャッシュレス決済</h3>
						<table class="table table-striped">
							<tbody>
								<tr>
									<th style="width: 30%">カード決済</th>
									<td>
										<p>
											VISA MASTER AMEX DINERS JCB SAISON＜セゾン＞ UC DC UFJ
											CENTRAL 銀聯 etc...
										</p>
										<p>
											<img
												src="img/store/finance/card_m_all.gif"
												class="img-fluid"
											/>
										</p>
									</td>
								</tr>
								<tr>
									<th>スマホ決済</th>
									<td>
										<p>
											PayPay、iD、QUIC PAY（Apple Pay）、<br />交通系電子マネー（Kitaca
											Suica PASMO tolca manaca ICOCA SUGOCA nimoca はやかけん）
										</p>
										<p>
											<img
												src="img/store/finance/emoney1.gif"
												class="img-fluid"
											/>
										</p>
										<p>
											<img
												src="img/store/finance/emoney2.gif"
												class="img-fluid"
											/>
										</p>
									</td>
								</tr>
								<tr>
									<th>キャッシュレス決済できないもの</th>
									<td>税金、自賠責保険、証紙代、印紙代、両替</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-12 col-lg-6 mb-5">
						<h3>銀行振込</h3>
						<table class="table table-striped">
							<tbody>
								<tr>
									<th>銀行名</th>
									<td>三井住友銀行（0009）</td>
								</tr>
								<tr>
									<th>支店名</th>
									<td>京都支店（496）</td>
								</tr>
								<tr>
									<th>口座番号</th>
									<td>普通口座 9212591</td>
								</tr>
								<tr>
									<th>口座名義</th>
									<td>有限会社アシスト</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-12 mb-5">
						<h3>パーツおよび整備のクレジット</h3>

						<div class="table-responsive-sm">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>取扱いクレジット会社</th>
										<th>ローン手数料</th>
										<th>支払回数</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>APLUS（アプラス）</th>
										<td>6.5％</td>
										<td>6～60回まで</td>
									</tr>
									<tr class="assist table-primary">
										<th>
											0％アシスト特典<span class="badge badge-primary ml-2"
												>オススメ</span
											>
										</th>
										<th>0％</th>
										<th>
											6～12回まで（手数料を当店負担します。対象についてはお問合わせください）
										</th>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="col-12 mb-5">
						<h3>車両販売のローン</h3>

						<div class="table-responsive">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th colspan="2">取扱いクレジット会社</th>
										<th>ローン手数料</th>
										<th>支払回数</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th rowspan="2">APLUS（アプラス）</th>
										<th>新車</th>
										<td>2.9～3.5％</td>
										<td>6～120回まで</td>
									</tr>
									<tr>
										<th>中古車</th>
										<td>2.9～3.5％</td>
										<td>6～120回まで</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "お支払い方法",
	components: {},
};
</script>

<style scoped lang="scss">
.table-bordered {
	thead {
		th {
			background-color: #f3f5f8;
		}
	}
}
.table-primary {
	td,
	th {
		background-color: #daf3fb;
	}
}
.assist {
	th {
		font-size: 1rem;
	}
}
</style>
